.navigation-item {
    padding-top: 9px;
    padding-bottom: 9px;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right:20px;
    text-decoration: none;
    color: #222222;
}

.navigation-item:hover {
    opacity: .9;
}

