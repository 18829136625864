.navigation-items{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 0%;
}

.navigation-items {
    font-family: Montserrat, sans-serif;
    color: rgb(26, 27, 31);
    line-height: 28px;
    font-weight: 400;
}