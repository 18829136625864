body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Fonts/montserrat/Montserrat-VariableFont_wght.ttf');
}
