.skills-container {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    
}

.skills-container__item {
    font-size: 16px;
    text-align: center;
    display: inline-block;
    flex: 0 1 auto;
    text-align: center;
    min-width: 100px;
}

.skills-container__item-name{
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
}

.skills-container__image {
    max-height: 100px;
}