.skills__code-picture {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-around;
    background-color: rgb(44, 51, 104);
    font-family: monospace;
    font-size: 80px;
    font-weight: 900;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skills__title {
    margin-bottom: 16px;
    font-weight: 1000;
    text-align: center;
    font-size: 44px;
    line-height: 62px;
    margin-top: 20px;
}
.skills {
    padding: 40px 30px 80px;
}