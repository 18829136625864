.about-me {
    background-color: rgb(245, 247, 250);
    border-bottom: 1px solid rgb(228, 235, 243);
    padding: 80px 30px 100px 30px;
    position: relative;
}

.about-me__image {
    max-width: 100%;
    vertical-align: middle;
}

.about-me__title {
    font-weight: 1000;
    font-size: 44px;
    text-align: center;
    line-height: 62px;
}

.about-me__items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; 
    font-family: Montserrat, sans-serif;
    color: rgb(26, 27, 31);
    font-size: 16px;
    line-height: 28px;
    font-weight: 50;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
}

.about-me__item {
    display: block;
    max-width: 46%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 450px;
}

.about-me__text {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    max-width: 361;
}