.navigation-title{
    font-family: Montserrat, sans-serif;
    color: rgb(126, 146, 228);
}

.navigation-title:hover {
    color: black;
    cursor: pointer;
}

a.navigation-title {
    display: block;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: ease;
    font-family: Montserrat, sans-serif;
    color: rgb(126, 146, 228);
    text-decoration: underline;
    font-size: 16px;
    line-height: 28px;
    font-weight: 50;
}
