.project {
    position: relative;
    padding: 80px 30px;
    border-bottom: 1px solid rgb(228, 235, 243);
    background-color: rgb(245, 247, 250);
}

.project-contents {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.vertical-split {
    display: flex;
    max-width: 46%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.vertical-title {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 44px;
    line-height: 62px;
    font-weight: 400;
}

.project-desc {
    font-family: Montserrat, sans-serif;
    color: rgb(26, 27, 31);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 24px;
    max-width: 400px;
}

.project-button {
    padding: 12px 25px;
    background-color: rgb(26, 27, 31);
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease;
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.project-button:hover {
    background-color: rgb(50, 52, 58);
    color: rgb(255, 255, 255);
}